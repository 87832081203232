import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['inputFile', 'formImport'];

  importFile(e) {
    e.preventDefault();
    this.inputFileTarget.click();
  }

  submitForm() {
    this.formImportTarget.submit();
  }
}
