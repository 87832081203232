// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require.context('../images', true, /\.(png|jpg|jpeg|svg|ico)$/);
import Rails from "@rails/ujs";
// import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"
// import "channels"
import '../src/application.scss';
// Turbolinks.start()
// ActiveStorage.start()
window.Rails = Rails;
import "adminator/src/assets/scripts/index";
import "controllers";
import 'select2';
import 'select2/dist/css/select2.css';
import '../packs/admin_user/edit.js'
Rails.start();

$(document).ready(function () {
  $('.js-select2-basic-single').prepend('<option disabled></option>').select2({
    placeholder: "",
    sorter: data => data.sort((a, b) => a.text.localeCompare(b.text))
  });
});
