import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  clipboardUrl(e) {
    const urlVerifyText = e.currentTarget.dataset["urlverify"];
    var input = document.createElement("textarea");
    input.value = urlVerifyText;
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    input.remove();
  }
}
