import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";
export default class extends Controller {
  static targets = ["ffsRequests", "paginate", "btnDelete"];

  static values = {
    isExistsFfsRegistered: Boolean
  };

  scroll() {
    let next_page = this.paginateTarget.querySelector("a[rel='next']");
    if (next_page == null) return;
    let url = next_page.href;
    var body = document.body;
    var html = document.documentElement;
    var height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    if (window.scrollY >= height - window.innerHeight) {
      this.loadMore(url);
    }
  }

  loadMore(url) {
    Rails.ajax({
      type: "GET",
      url: url,
      dataType: "json",
      success: (data) => {
        this.ffsRequestsTarget.insertAdjacentHTML(
          "beforeend",
          data.ffs_requests
        );
        this.paginateTarget.innerHTML = data.paginate;

        if ($(".check-all-input:checked").length == 0) return;
        $(".selectable").prop("checked", true);
      },
      error: () => {
        alert("エラーが発生しました。");
        location.reload();
      }
    });
  }

  actionDeleteMultipleFfsRequest(e) {
    e.preventDefault();
    const inputChecked = $(".selectable:checked");
    if (inputChecked.length == 0) {
      alert("削除したい項目を選択してください。");
      location.reload();
      return;
    }

    if (confirm("本当に削除しますか？")) {
      let url = new URLSearchParams(window.location.search);
      const ids = this.findFfsRequestIds(inputChecked);
      let inputHidden = document.createElement("input");
      inputHidden.setAttribute("type", "hidden");
      inputHidden.setAttribute("name", "ffs_request_ids");
      inputHidden.setAttribute("value", `${ids}`);
      this.btnDeleteTarget.parentNode.append(inputHidden);

      let inputHiddenStatus = document.createElement("input");
      inputHiddenStatus.setAttribute("type", "hidden");
      inputHiddenStatus.setAttribute("name", "status");
      inputHiddenStatus.setAttribute("value", `${url.get("status")}`);
      this.btnDeleteTarget.parentNode.append(inputHiddenStatus);
      this.btnDeleteTarget.parentNode.submit();
      inputHidden.remove();
    }
  }

  findFfsRequestIds(inputChecked) {
    return inputChecked
      .map(function () {
        return $(this).val();
      })
      .get();
  }

  actionCheckedAll() {
    if (!this.isExistsFfsRegisteredValue) return;
    if ($(".check-all-input:checked").length == 0) {
      this.enableBtnDelete();
    } else {
      this.disableBtnDelete();
    }
  }

  actionChecked(e) {
    if (e.currentTarget.dataset.isRegistered == "false") return;
    if ($(".selectable:checked[data-is-registered='true']").length == 0) {
      this.enableBtnDelete();
      return;
    }
    this.disableBtnDelete();
  }

  disableBtnDelete() {
    this.btnDeleteTarget.classList.add("disabled");
  }

  enableBtnDelete() {
    this.btnDeleteTarget.classList.remove("disabled");
  }
}
