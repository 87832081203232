import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    urlAll: String,
    urlSearchResults: String,
    urlOnlySelectedItems: String,
    ffsRequestsTakenExam: Boolean,
    nameParamsInput: String
  };

  setForm(e) {
    const formDownload = $("#download-button").find("form");
    const typeDownload = e.currentTarget.dataset["typeDownload"];
    const inputChecked = $(".selectable:checked");
    const checkedAllActive = $(".check-all-input:checked").length == 1;
    const nameInput = this.nameParamsInputValue;

    if (typeDownload == "all") {
      this.actionSetPath(formDownload, this.urlAllValue);
      formDownload.find(`input:hidden[name='${nameInput}']`).remove();
    } else if (typeDownload == "search-results") {
      this.actionSetPath(formDownload, this.urlSearchResultsValue);
      formDownload.find(`input:hidden[name='${nameInput}']`).remove();
    } else if (typeDownload == "only-selected-items") {
      formDownload.find(`input:hidden[name='${nameInput}']`).remove();
      if (inputChecked.length == 0) {
        alert("ダウンロードしたい項目を選択してください。");
        location.reload();
      }

      if (checkedAllActive && nameInput == "ffs_request_ids") {
        this.actionSetPath(formDownload, this.urlSearchResultsValue);
        formDownload.find(`input:hidden[name='${nameInput}']`).remove();
        return;
      }
      var ids = this.findIds(inputChecked);

      var inputHidden = document.createElement("input");
      inputHidden.setAttribute("type", "hidden");
      inputHidden.setAttribute("name", `${nameInput}`);
      inputHidden.setAttribute("value", `${ids}`);
      formDownload.append(inputHidden);
      this.actionSetPath(formDownload, this.urlOnlySelectedItemsValue);
    } else {
      alert("エラーが発生しました。");
      location.reload();
    }
  }

  findIds(inputChecked) {
    return inputChecked
      .map(function () {
        return $(this).val();
      })
      .get();
  }

  actionSetPath(form, path) {
    form.attr("action", path);
  }
}
