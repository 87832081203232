import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['inputFile', 'formImport'];
  static values = {
    urlValidateFfs: String,
    urlFfs: String
  }

  importFile(e) {
    e.preventDefault();
    this.inputFileTarget.click();
  }

  submitForm() {
    var formData = new FormData(this.formImportTarget);
    $('#loader-ffs').removeClass('hidden')
    Rails.ajax({
      url: this.urlValidateFfsValue,
      type: 'POST',
      contentType: "application/json",
      data: formData,
      success: function (data) {
        $("#import-ffs-request-info-wrapper").html(data);
        $('#import-ffs-request-info').addClass("show");
        $('#import-ffs-request-info').show();
      },
      complete: function () {
        $('#loader-ffs').addClass('hidden')
      },
      errors: function (data) { },
    });
  }

  submitData(e) {
    e.currentTarget.classList.add("disabled");
    $('#import-ffs-request-info').removeClass("show");
    $("#import-ffs-request-info").hide();
    this.formImportTarget.submit();
  }

  cancelSubmit(e) {
    location.reload();
  }
}
