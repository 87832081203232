import * as $ from "jquery";

$(document).ready(() => {
  $("#tree input:checkbox").change(function (e) {
    var val = $(this).is(":checked");
    $(this)
      .parents("li.list-unstyled:first")
      .find("input:checkbox")
      .each(function () {
        if (val) {
          $(this).prop("checked", true);
          if ($(this)[0] === e.currentTarget) return;
          $(this).addClass("disabled");
        } else {
          $(this).prop("checked", false);
          $(this).removeClass("disabled");
        }
      });
  });
});
