import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['inputFile', 'spanNameFile', 'formImport'];
  static values = {
    urlImportValidateEmployee: String,
    urlImportEmployee: String
  }
  appendFileName() {
    var fileName = this.inputFileTarget.value.split(/(\\|\/)/g).pop();
    this.spanNameFileTarget.textContent = fileName;
  }

  submitForm(e) {
    e.preventDefault();
    if (this.inputFileTarget.files.length == 0) {
      alert("ファイルを追加してください")
      return;
    }

    var formData = new FormData(this.formImportTarget);
    $('#loader-ffs').removeClass('hidden')
    Rails.ajax({
      url: this.urlImportValidateEmployeeValue,
      type: 'DELETE',
      contentType: "application/json",
      data: formData,
      success: function (data) {
        $("#import-employee-by-id-info-wrapper").html(data)
        $('#import-employee-by-id-info').addClass("show");
        $('#import-employee-by-id-info').show();
      },
      complete: function () {
        $('#loader-ffs').addClass('hidden')
      },
      errors: function (data) { },
    });
  }

  submitData(e) {
    e.currentTarget.classList.add("disabled");
    $('#import-employee-by-id-info').removeClass("show");
    $("#import-employee-by-id-info").hide();
    this.formImportTarget.submit();
  }

  cancelSubmit() {
    location.reload();
  }
}
